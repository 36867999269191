body {
  -ms-overflow-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $off-white;
  color: $dark-gray;
  font-family: $sans;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

article {
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

p {
  font-size: 23px;
  line-height: 29px;

  @media screen and (max-width: $mdViewport) {
    font-size: 19px;
    line-height: 26px;
  }
}

.hangul {
  font-family: $hangul;
}

.h1 {
  font-family: $display;
  font-size: 72px;
  line-height: 78px;
}
