.canvas {
  background-color: transparent;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;
}

.shared__canvas {
  background-color: transparent;
  height: 100% !important;
  width: 100% !important;
}
