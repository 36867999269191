.ComingSoon {
  font-family: Helvetica;
  font-weight: 700;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.ComingSoon-background {
  font-size: 25vh;
  line-height: 25vh;
  overflow: hidden;
  text-transform: uppercase;
}

.ComingSoon-marquee {
  color: #fff;
  max-width: 100vw;
  overflow: hidden;
  pointer-events: none;
  text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
  white-space: nowrap;

  > span {
    animation: marquee 55s linear infinite;
    display: inline-block;
  }

  &.ComingSoon-marquee__reverse > span {
    animation: marquee-reverse 55s linear infinite;
  }
}

.ComingSoon-hero {
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  margin-top: -15px;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.ComingSoon-heroText {
  font-size: 120px;
}

.ComingSoon-cta {
  background-color: #000;
  border: 2px solid transparent;
  bottom: 20px;
  color: #fff;
  padding: 15px 30px;
  position: absolute;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fff;
    border: 2px solid #000;
    color: #000;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-reverse {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 480px) {
  .ComingSoon-hero {
    align-items: flex-start;
    margin-left: 10px;
  }

  .ComingSoon-heroText {
    font-size: 100px;
    line-height: 85px;
  }

  .ComingSoon-cta {
    margin-left: 5px;
  }
}

@media screen and (max-height: 400px) {
  .ComingSoon-heroText {
    font-size: 90px;
  }
}
