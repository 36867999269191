.everythingElse {
  .caseStudy__video {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 375px;
  }
}

.everythingElse__etc {
  .caseStudy__image {
    margin-bottom: 30px;
  }

  .caseStudy__cta {
    margin-bottom: 100px;
  }
}

.everythingElse__h1 {
  font-size: 48px;
  line-height: 54px;
}

@media screen and (max-width: $mdViewport) {
  .everythingElse__etc {
    .caseStudy__image {
      margin-bottom: 20px;
    }

    .caseStudy__cta {
      font-size: 18px;
      margin-bottom: 50px;
    }
  }

  .everythingElse__h1 {
    font-size: 36px;
    line-height: 42px;
  }
}
