.shape {
  pointer-events: none;
}

.shape-overlays {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 50;
}

.shape-overlays__path {
  fill: rgba($dark-gray, 0.975);
  transition: fill $long $cubic-bezier;
}

body.no-scroll {
  .shape-overlays__path {
    fill: rgba($dark-gray, 0.5);
  }
}

.transitionOverlay.shape-overlays {
  z-index: 500;

  .shape-overlays__path {
    fill: $dark-gray;
    transition-duration: 0ms;
  }
}
