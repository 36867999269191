body.menu-is-open {
  overflow: hidden;
  pointer-events: none;
}

.nav {
  height: 75px;
  left: 100%;
  padding: 0 30px 0 20px;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transform: rotate(90deg);
  transform-origin: top left;
  width: 100vh;
  z-index: 100;

  @media screen and (max-width: $mdViewport) {
    height: 45px;
    padding: 0 45px 0 7.5px;
  }

  @media screen and (max-width: $smViewport) {
    padding: 0 20vh 0 7.5px;
  }
}

.nav__container {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 10;
}

#logo {
  width: 35px;

  > path {
    fill: $dark-gray;
    transition: fill $mid $cubic-bezier;
  }

  @media screen and (max-width: $smViewport) {
    width: 30px;
  }
}

.nav__link {
  color: $dark-gray;
  text-decoration: none;
  transition: color $mid $cubic-bezier;
}

.nav__about.nav--about {
  pointer-events: none;
}

.nav__burgerBox {
  cursor: pointer;
  display: flex;
  align-items: center;

  > span {
    transition: color $mid $cubic-bezier;
  }

  @media screen and (max-width: $mdViewport) {
    > span {
      display: none;
    }
  }
}

.nav__hamburger {
  display: flex;
  flex-flow: column;
  height: 36px;
  justify-content: space-between;
  padding: 10px;
  width: 50px;

  > span {
    background-color: $dark-gray;
    display: inline-block;
    height: 2px;
    position: relative;
    top: 0;
    transition: background $mid $cubic-bezier,
      transform $short $cubic-bezier,
      top $short $cubic-bezier $short;
    width: 100%;
  }
}

.nav__menu {
  background-color: transparent;
  height: 100vh;
  left: 0;
  min-height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 0;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
  transition: visibility 0ms $cubic-bezier $long;
  visibility: hidden;
  width: 100vw;
  z-index: 1;

  &.is-open {
    transition-delay: 0ms;
    visibility: visible;

    .nav__menuCta {
      opacity: 1;
      transition-delay: $long;
    }
  }
}

.nav__menuContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  overflow-y: auto;
  padding: 50px 30px;
  position: relative;

  @media screen and (max-width: $smViewport) {
    justify-content: flex-start;
  }
}

.nav__menuLink {
  color: $white;
  display: inline-block;
  font-size: 40px;
  font-family: $display;
  opacity: 0;
  padding-left: 34px;
  position: relative;
  text-decoration: none;
  transform: translateY(-15px);
  white-space: nowrap;

  &:before {
    color: $blue;
    content: '0' counter(item);
    counter-increment: item;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    left: 0;
    letter-spacing: 1.5px;
    position: absolute;
    top: 3px;
  }

  &:after {
    background-color: rgba($red, 1);
    content: '';
    height: 4px;
    left: 30px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity $short $cubic-bezier;
    width: calc(100% - 26px);
  }
}

.nav__menuLink--inactive {
  color: rgba($dark-gray, 0.5);

  &:before {
    color: rgba($blue, 0.5);
  }
}

.nav__items {
  counter-reset: item;

  &.nav--verizon {
    .nav__item:nth-child(1) {
      pointer-events: none;
      opacity: 0.5;

      .nav__menuLink:after {
        opacity: 1;
      }
    }
  }

  &.nav--trinet {
    .nav__item:nth-child(2) {
      pointer-events: none;
      opacity: 0.5;

      .nav__menuLink:after {
        opacity: 1;
      }
    }
  }

  &.nav--cartier {
    .nav__item:nth-child(3) {
      pointer-events: none;
      opacity: 0.5;

      .nav__menuLink:after {
        opacity: 1;
      }
    }
  }

  &.nav--marquee-sports {
    .nav__item:nth-child(4) {
      pointer-events: none;
      opacity: 0.5;

      .nav__menuLink:after {
        opacity: 1;
      }
    }
  }

  &.nav--odyssey {
    .nav__item:nth-child(5) {
      pointer-events: none;
      opacity: 0.5;

      .nav__menuLink:after {
        opacity: 1;
      }
    }
  }

  &.nav--etc {
    .nav__item:nth-child(6) {
      pointer-events: none;
      opacity: 0.5;

      .nav__menuLink:after {
        opacity: 1;
      }
    }
  }

  @media screen and (max-height: $smViewport) {
    padding: 50px 0;
  }
}

.nav__item:not(:last-child) {
  margin-bottom: 24px;

  @media screen and (max-width: $smViewport) {
    margin-bottom: 35px;
  }
}

.nav__menuCta {
  bottom: 20px;
  color: $white;
  font-size: 14px;
  left: 50%;
  opacity: 0;
  max-width: 580px;
  padding: 0 50px;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity $mid $cubic-bezier;
  width: 100%;
}

.nav__menuEmail {
  color: $blue;
  text-decoration: none;
}

.nav__container.is-open {
  .nav__link {
    color: $blue;
  }

  #logo > path {
    fill: $blue;
  }

  .nav__burgerBox > span {
    color: $blue;
  }

  .nav__hamburger {
    > span:not(:nth-child(2)) {
      background-color: $blue;
      transition: background $mid $cubic-bezier,
        transform $short $cubic-bezier $short,
        top $short $cubic-bezier;
    }

    > span:first-child {
      top: 7px;
      transform: rotate(-45deg);
    }

    > span:nth-child(2) {
      background-color: transparent;
    }

    > span:last-child {
      top: -7px;
      transform: rotate(45deg);
    }
  }
}
