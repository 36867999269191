.image {
  background-color: $navy;
  font-size: 0;
  overflow: hidden;
  border-radius: 10px;

  > img {
    opacity: 0;
    transition: opacity $mid $cubic-bezier;
    width: 100%;
  }

  &.is-visible {
    > img {
      opacity: 1;
    }
  }
}
