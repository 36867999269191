@import url(https://fonts.googleapis.com/css?family=Black+Han+Sans:400);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,600);
@import url(https://fonts.googleapis.com/css?family=Staatliches);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  -ms-overflow-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFEFEF;
  color: #222222;
  font-family: "Work Sans", "Helvetica", "sans-serif";
  margin: 0;
  padding: 0;
  scrollbar-width: none; }

body::-webkit-scrollbar {
  display: none; }

* {
  box-sizing: border-box; }

article {
  min-height: 100vh;
  position: relative;
  z-index: 1; }

p {
  font-size: 23px;
  line-height: 29px; }
  @media screen and (max-width: 767px) {
    p {
      font-size: 19px;
      line-height: 26px; } }

.hangul {
  font-family: "Black Han Sans"; }

.h1 {
  font-family: "Staatliches", "serif";
  font-size: 72px;
  line-height: 78px; }

.home {
  height: 100vh;
  position: relative; }

.home__header {
  align-items: center;
  display: flex;
  height: 100vh;
  left: 0;
  overflow: hidden;
  padding: 0 40px;
  position: absolute;
  top: 0;
  width: 100%; }

.home__headerText {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%; }

.home__large > div {
  font-size: 110px;
  font-weight: 700;
  line-height: 120px;
  margin-left: -5px; }

.home__h1 {
  font-family: "Staatliches", "serif";
  font-size: 140px;
  font-weight: 400;
  line-height: 130px; }

.home__sub {
  font-size: 23px;
  line-height: 29px;
  margin-top: 30px;
  position: relative; }

.home__subsub {
  margin: 10px 0 25px; }

@media screen and (max-width: 767px) {
  .home__header {
    padding: 0 30px; }
  .home__large > div {
    font-size: 90px;
    line-height: 90px; }
  .home__h1 {
    font-size: 100px;
    line-height: 110px; }
  .home__sub {
    font-size: 19px;
    line-height: 23px;
    margin-top: 10px; } }

@media screen and (max-width: 479px) {
  .home__header {
    padding: 0 20px; }
  .home__large > div {
    font-size: 65px;
    line-height: 72px;
    margin-left: -3px; }
  .home__h1 {
    font-size: 80px;
    line-height: 90px; }
  .home__sub {
    margin-top: 15px; } }

.about {
  margin: 0 auto;
  max-width: 1200px;
  padding: 120px 0 150px; }

.about__hover,
.about__link {
  color: #222222;
  position: relative;
  text-decoration: none; }
  .about__hover:after,
  .about__link:after {
    background-color: #00BCE8;
    bottom: 4px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transition: width 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%; }

.about__link {
  cursor: pointer;
  font-weight: 600; }

.about__intro,
.about__what,
.about__where {
  width: 60%; }

.about__intro {
  position: relative;
  z-index: 1; }

.about__who {
  align-items: center;
  display: flex;
  justify-content: space-between; }

.about__where {
  margin: 0 auto; }

.about__images {
  height: 300px;
  position: relative;
  width: 40%; }

.about__image {
  box-shadow: 0 0 10px rgba(34, 34, 34, 0.3);
  left: 50%;
  margin: 0 20px;
  max-width: 450px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: scale(1.075) translate(-50%, -50%);
  width: 100%; }
  .about__image.is-visible {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%); }

.about__what {
  margin: 75px 0 0 auto; }

.about__where {
  margin-top: 75px; }

.about__h1 {
  font-size: 120px;
  line-height: 120px; }

.about__p {
  font-size: 28px;
  line-height: 36px;
  margin-top: 10px; }

@media screen and (max-width: 1279px) {
  .about__who,
  .about__what {
    padding: 0 50px; } }

@media screen and (max-width: 1079px) {
  .about__what,
  .about__where {
    width: 70%; } }

@media screen and (max-width: 767px) {
  .about {
    padding: 40px 0 80px; }
  .about__h1 {
    font-size: 72px;
    line-height: 72px; }
  .about__hover:after,
  .about__link:after {
    bottom: 2px; }
  .about__images {
    height: 280px;
    overflow: hidden;
    width: 100%; }
  .about__image {
    height: calc(100% - 20px);
    margin: 0;
    overflow: hidden;
    top: 10px;
    transform: scale(1.075) translate(-50%, 0);
    width: calc(100% - 20px); }
    .about__image.is-visible {
      transform: scale(1) translate(-50%, 0); }
  .about__who {
    flex-flow: column-reverse; }
  .about__who,
  .about__what,
  .about__where {
    padding: 0 45px 0 20px; }
  .about__intro {
    margin-top: -70px; }
  .about__intro,
  .about__what,
  .about__where {
    width: 100%; }
  .about__what,
  .about__where {
    margin: 40px auto 0; }
  .about__p {
    font-size: 19px;
    line-height: 26px;
    margin-top: 10px; } }

@media screen and (max-width: 767px) and (max-height: 400px) {
  .about__images {
    height: 220px; } }

@media screen and (max-width: 479px) {
  .about {
    padding-top: 5px; }
  .about__intro {
    margin-top: -80px; }
  .about__images {
    height: 240px;
    transform: translateX(-10px);
    min-width: 320px; } }

@media screen and (min-width: 767px) {
  .about__hover:hover:after,
  .about__link:hover:after {
    left: auto;
    right: 0;
    width: 0; } }

.caseStudy {
  margin: 0 auto;
  max-width: 1200px;
  padding-bottom: 100vh;
  pointer-events: none;
  position: relative;
  z-index: 10; }

.caseStudy__header {
  min-height: 100vh; }
  @media screen and (max-width: 479px) {
    .caseStudy__header {
      min-height: auto; } }

.caseStudy__header,
.caseStudy__images {
  pointer-events: auto; }

.caseStudy__h1 {
  font-size: 90px;
  line-height: 100px;
  margin-top: 40vh; }

.caseStudy__p,
.caseStudy__note {
  margin-top: 20px;
  width: 75%; }

.caseStudy__note {
  font-size: 16px;
  line-height: 1.2; }

.caseStudy__cta {
  color: #00BCE8;
  font-size: 23px;
  margin-top: 25px; }

.caseStudy__link {
  color: #00BCE8;
  position: relative;
  text-decoration: none; }
  .caseStudy__link:after {
    background-color: #00BCE8;
    bottom: 1px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transition: width 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%; }

.caseStudy__aside {
  margin: 60px auto 0;
  width: 60%; }

.caseStudy__h2 {
  color: #E54C4C;
  font-family: "Staatliches", "serif";
  font-size: 60px;
  margin-bottom: 20px; }

.caseStudy__emphasis {
  font-weight: 600; }

.caseStudy__images {
  align-items: center;
  display: flex;
  flex-flow: column;
  margin: 120px auto 0;
  max-width: 1200px; }

.caseStudy__vimeo {
  background-color: #212D3A;
  position: relative;
  padding-top: 56.25%;
  width: 100%; }

.caseStudy__vimeoVideo {
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  top: 0; }

.caseStudy__vimeo.is-visible .caseStudy__vimeoVideo {
  opacity: 1; }

.caseStudy__video,
.caseStudy__image {
  width: 100%; }

.caseStudy__image,
.caseStudy__video,
.caseStudy__vimeo {
  box-shadow: 0 0 10px rgba(34, 34, 34, 0.3);
  margin-bottom: 50px; }

.caseStudy__next {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  visibility: hidden;
  width: 100%;
  z-index: 1; }

.caseStudy__nextCta {
  color: #222222;
  font-family: "Staatliches", "serif";
  text-align: center;
  text-decoration: none; }

.caseStudy__nextLabel {
  display: block;
  font-size: 72px; }
  .caseStudy__nextLabel:after {
    background-color: #222222;
    content: '';
    display: block;
    height: 4px;
    margin: 15px auto;
    width: 40px; }

.caseStudy__caseStudy {
  color: #00BCE8;
  font-size: 120px; }

body.is-showing-next .caseStudy__next {
  opacity: 1;
  visibility: visible; }

@media screen and (max-width: 1279px) {
  .caseStudy {
    padding: 0 50px 100vh; } }

@media screen and (max-width: 767px) {
  .caseStudy {
    padding: 0 42.5px 100vh 20px; }
  .caseStudy__h1 {
    font-size: 60px;
    line-height: 68px;
    margin-top: 120px; }
  .caseStudy__h2 {
    font-size: 48px; }
  .caseStudy__p,
  .caseStudy__note,
  .caseStudy__aside {
    width: 100%; }
  .caseStudy__images {
    margin-top: 60px; }
  .caseStudy__nextLabel {
    font-size: 60px; }
  .caseStudy__caseStudy {
    font-size: 80px; } }

@media screen and (max-width: 479px) {
  .caseStudy__video,
  .caseStudy__image,
  .caseStudy__vimeo {
    margin-bottom: 25px; }
  .caseStudy__caseStudy {
    font-size: 72px; }
  .caseStudy__scroll {
    display: none; } }

@media screen and (min-width: 767px) {
  .caseStudy__link:hover:after {
    left: auto;
    right: 0;
    width: 0; } }

.odyssey .caseStudy__video,
.odyssey .caseStudy__image {
  max-width: 375px; }

.everythingElse .caseStudy__video {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 375px; }

.everythingElse__etc .caseStudy__image {
  margin-bottom: 30px; }

.everythingElse__etc .caseStudy__cta {
  margin-bottom: 100px; }

.everythingElse__h1 {
  font-size: 48px;
  line-height: 54px; }

@media screen and (max-width: 767px) {
  .everythingElse__etc .caseStudy__image {
    margin-bottom: 20px; }
  .everythingElse__etc .caseStudy__cta {
    font-size: 18px;
    margin-bottom: 50px; }
  .everythingElse__h1 {
    font-size: 36px;
    line-height: 42px; } }

.ComingSoon {
  font-family: Helvetica;
  font-weight: 700;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative; }

.ComingSoon-background {
  font-size: 25vh;
  line-height: 25vh;
  overflow: hidden;
  text-transform: uppercase; }

.ComingSoon-marquee {
  color: #fff;
  max-width: 100vw;
  overflow: hidden;
  pointer-events: none;
  text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
  white-space: nowrap; }
  .ComingSoon-marquee > span {
    animation: marquee 55s linear infinite;
    display: inline-block; }
  .ComingSoon-marquee.ComingSoon-marquee__reverse > span {
    animation: marquee-reverse 55s linear infinite; }

.ComingSoon-hero {
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  margin-top: -15px;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10; }

.ComingSoon-heroText {
  font-size: 120px; }

.ComingSoon-cta {
  background-color: #000;
  border: 2px solid transparent;
  bottom: 20px;
  color: #fff;
  padding: 15px 30px;
  position: absolute;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }
  .ComingSoon-cta:hover {
    background-color: #fff;
    border: 2px solid #000;
    color: #000; }

@keyframes marquee {
  0% {
    transform: translateX(0%); }
  100% {
    transform: translateX(-100%); } }

@keyframes marquee-reverse {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0%); } }

@media screen and (max-width: 480px) {
  .ComingSoon-hero {
    align-items: flex-start;
    margin-left: 10px; }
  .ComingSoon-heroText {
    font-size: 100px;
    line-height: 85px; }
  .ComingSoon-cta {
    margin-left: 5px; } }

@media screen and (max-height: 400px) {
  .ComingSoon-heroText {
    font-size: 90px; } }

body.no-scroll,
.wrapper.should-animate-in-out {
  overflow: hidden; }
  body.no-scroll .wrapper__bg,
  .wrapper.should-animate-in-out .wrapper__bg {
    opacity: 0;
    transform: scale(0.8); }

.transition__logo {
  height: 100px;
  left: 50%;
  opacity: 1;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 400ms cubic-bezier(0.645, 0.045, 0.355, 1), width 400ms cubic-bezier(0.645, 0.045, 0.355, 1), height 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100px;
  z-index: 501; }
  .transition__logo .st0 {
    fill: #FFFFFF; }
  .transition__logo.should-animate-out {
    height: 150px;
    opacity: 0;
    width: 150px; }

.page {
  transition: opacity 500ms cubic-bezier(0.645, 0.045, 0.355, 1) 200ms; }

.wrapper {
  overflow-x: hidden; }
  .wrapper:before {
    background-color: rgba(255, 255, 255, 0);
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: background 400ms ease-in-out;
    width: 100vw;
    z-index: 0; }

.wrapper.has-background:before {
  background-color: rgba(255, 255, 255, 0.8);
  transition-duration: 500ms; }

.wrapper__bg {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: scale(1);
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  z-index: -1; }

.wrapper__bgText {
  color: transparent;
  font-family: "Black Han Sans";
  font-size: 150vh;
  transform: translateY(5%);
  white-space: nowrap;
  -webkit-text-stroke: 2px #FFFFFF; }

.Transition-enter {
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition-delay: 0ms;
  transition-duration: 0ms;
  width: 100%;
  z-index: 1; }

.Transition-enter-active {
  opacity: 0; }

.Transition-enter-done {
  opacity: 1; }

.Transition-exit {
  opacity: 1;
  overflow: hidden;
  position: relative;
  transition-delay: 0ms;
  z-index: 10; }

.Transition-exit-active {
  opacity: 0; }

.canvas {
  background-color: transparent;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1; }

.shared__canvas {
  background-color: transparent;
  height: 100% !important;
  width: 100% !important; }

body.menu-is-open {
  overflow: hidden;
  pointer-events: none; }

.nav {
  height: 75px;
  left: 100%;
  padding: 0 30px 0 20px;
  pointer-events: auto;
  position: fixed;
  top: 0;
  transform: rotate(90deg);
  transform-origin: top left;
  width: 100vh;
  z-index: 100; }
  @media screen and (max-width: 767px) {
    .nav {
      height: 45px;
      padding: 0 45px 0 7.5px; } }
  @media screen and (max-width: 479px) {
    .nav {
      padding: 0 20vh 0 7.5px; } }

.nav__container {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 10; }

#logo {
  width: 35px; }
  #logo > path {
    fill: #222222;
    transition: fill 300ms cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media screen and (max-width: 479px) {
    #logo {
      width: 30px; } }

.nav__link {
  color: #222222;
  text-decoration: none;
  transition: color 300ms cubic-bezier(0.645, 0.045, 0.355, 1); }

.nav__about.nav--about {
  pointer-events: none; }

.nav__burgerBox {
  cursor: pointer;
  display: flex;
  align-items: center; }
  .nav__burgerBox > span {
    transition: color 300ms cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media screen and (max-width: 767px) {
    .nav__burgerBox > span {
      display: none; } }

.nav__hamburger {
  display: flex;
  flex-flow: column;
  height: 36px;
  justify-content: space-between;
  padding: 10px;
  width: 50px; }
  .nav__hamburger > span {
    background-color: #222222;
    display: inline-block;
    height: 2px;
    position: relative;
    top: 0;
    transition: background 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1), top 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 200ms;
    width: 100%; }

.nav__menu {
  background-color: transparent;
  height: 100vh;
  left: 0;
  min-height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 0;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
  transition: visibility 0ms cubic-bezier(0.645, 0.045, 0.355, 1) 400ms;
  visibility: hidden;
  width: 100vw;
  z-index: 1; }
  .nav__menu.is-open {
    transition-delay: 0ms;
    visibility: visible; }
    .nav__menu.is-open .nav__menuCta {
      opacity: 1;
      transition-delay: 400ms; }

.nav__menuContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  overflow-y: auto;
  padding: 50px 30px;
  position: relative; }
  @media screen and (max-width: 479px) {
    .nav__menuContainer {
      justify-content: flex-start; } }

.nav__menuLink {
  color: #FFFFFF;
  display: inline-block;
  font-size: 40px;
  font-family: "Staatliches", "serif";
  opacity: 0;
  padding-left: 34px;
  position: relative;
  text-decoration: none;
  transform: translateY(-15px);
  white-space: nowrap; }
  .nav__menuLink:before {
    color: #00BCE8;
    content: "0" counter(item);
    counter-increment: item;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    left: 0;
    letter-spacing: 1.5px;
    position: absolute;
    top: 3px; }
  .nav__menuLink:after {
    background-color: #e54c4c;
    content: '';
    height: 4px;
    left: 30px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    width: calc(100% - 26px); }

.nav__menuLink--inactive {
  color: rgba(34, 34, 34, 0.5); }
  .nav__menuLink--inactive:before {
    color: rgba(0, 188, 232, 0.5); }

.nav__items {
  counter-reset: item; }
  .nav__items.nav--verizon .nav__item:nth-child(1) {
    pointer-events: none;
    opacity: 0.5; }
    .nav__items.nav--verizon .nav__item:nth-child(1) .nav__menuLink:after {
      opacity: 1; }
  .nav__items.nav--trinet .nav__item:nth-child(2) {
    pointer-events: none;
    opacity: 0.5; }
    .nav__items.nav--trinet .nav__item:nth-child(2) .nav__menuLink:after {
      opacity: 1; }
  .nav__items.nav--cartier .nav__item:nth-child(3) {
    pointer-events: none;
    opacity: 0.5; }
    .nav__items.nav--cartier .nav__item:nth-child(3) .nav__menuLink:after {
      opacity: 1; }
  .nav__items.nav--marquee-sports .nav__item:nth-child(4) {
    pointer-events: none;
    opacity: 0.5; }
    .nav__items.nav--marquee-sports .nav__item:nth-child(4) .nav__menuLink:after {
      opacity: 1; }
  .nav__items.nav--odyssey .nav__item:nth-child(5) {
    pointer-events: none;
    opacity: 0.5; }
    .nav__items.nav--odyssey .nav__item:nth-child(5) .nav__menuLink:after {
      opacity: 1; }
  .nav__items.nav--etc .nav__item:nth-child(6) {
    pointer-events: none;
    opacity: 0.5; }
    .nav__items.nav--etc .nav__item:nth-child(6) .nav__menuLink:after {
      opacity: 1; }
  @media screen and (max-height: 479px) {
    .nav__items {
      padding: 50px 0; } }

.nav__item:not(:last-child) {
  margin-bottom: 24px; }
  @media screen and (max-width: 479px) {
    .nav__item:not(:last-child) {
      margin-bottom: 35px; } }

.nav__menuCta {
  bottom: 20px;
  color: #FFFFFF;
  font-size: 14px;
  left: 50%;
  opacity: 0;
  max-width: 580px;
  padding: 0 50px;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%; }

.nav__menuEmail {
  color: #00BCE8;
  text-decoration: none; }

.nav__container.is-open .nav__link {
  color: #00BCE8; }

.nav__container.is-open #logo > path {
  fill: #00BCE8; }

.nav__container.is-open .nav__burgerBox > span {
  color: #00BCE8; }

.nav__container.is-open .nav__hamburger > span:not(:nth-child(2)) {
  background-color: #00BCE8;
  transition: background 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1) 200ms, top 200ms cubic-bezier(0.645, 0.045, 0.355, 1); }

.nav__container.is-open .nav__hamburger > span:first-child {
  top: 7px;
  transform: rotate(-45deg); }

.nav__container.is-open .nav__hamburger > span:nth-child(2) {
  background-color: transparent; }

.nav__container.is-open .nav__hamburger > span:last-child {
  top: -7px;
  transform: rotate(45deg); }

.shape {
  pointer-events: none; }

.shape-overlays {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 50; }

.shape-overlays__path {
  fill: rgba(34, 34, 34, 0.975);
  transition: fill 400ms cubic-bezier(0.645, 0.045, 0.355, 1); }

body.no-scroll .shape-overlays__path {
  fill: rgba(34, 34, 34, 0.5); }

.transitionOverlay.shape-overlays {
  z-index: 500; }
  .transitionOverlay.shape-overlays .shape-overlays__path {
    fill: #222222;
    transition-duration: 0ms; }

.common__h1 {
  color: transparent;
  display: inline-block;
  position: relative;
  transition: color 0ms cubic-bezier(0.645, 0.045, 0.355, 1) 200ms; }
  .common__h1:before {
    background-color: #E54C4C;
    content: '';
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: width 300ms cubic-bezier(0.645, 0.045, 0.355, 1) 200ms, height 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%; }
  .common__h1.is-transitioned {
    color: #E54C4C; }
    .common__h1.is-transitioned:before {
      height: 100%;
      width: 0; }

.image {
  background-color: #212D3A;
  font-size: 0;
  overflow: hidden;
  border-radius: 10px; }
  .image > img {
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%; }
  .image.is-visible > img {
    opacity: 1; }

.video {
  background-color: #212D3A;
  font-size: 0;
  overflow: hidden;
  border-radius: 10px; }
  .video > video {
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%; }
  .video > video[poster] {
    object-fit: cover; }
  .video.is-visible > video {
    opacity: 1; }

.button {
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  transition: background 400ms cubic-bezier(0.645, 0.045, 0.355, 1); }
  .button:nth-child(2) {
    margin-top: 15px; }

.button__top,
.button__right,
.button__bottom,
.button__left {
  transition-delay: 0.2s; }

.button__top,
.button__right,
.button__bottom,
.button__left,
.button__top:before,
.button__right:before,
.button__bottom:before,
.button__left:before {
  background-color: #00BCE8;
  display: block;
  position: absolute;
  transition: all 400ms ease-in-out; }

.button__top:before,
.button__right:before,
.button__bottom:before,
.button__left:before {
  content: '';
  height: 100%;
  left: 0;
  top: 0;
  transition-delay: 0s;
  width: 100%; }

.button__top,
.button__bottom {
  height: 1px;
  width: 100%; }

.button__right,
.button__left {
  height: 100%;
  width: 1px; }

.button__top {
  left: 0;
  top: 0; }
  .button__top:before {
    transform: translateX(-200%); }

.button__right {
  right: 0;
  top: 0; }
  .button__right:before {
    transform: translateY(-200%); }

.button__bottom,
.button__left {
  bottom: 0;
  left: 0; }

.button__bottom:before {
  transform: translateX(200%); }

.button__left:before {
  transform: translateY(200%); }

.button__link {
  color: #222222;
  text-decoration: none;
  transition: color 300ms cubic-bezier(0.645, 0.045, 0.355, 1); }

@media screen and (min-width: 767px) {
  .button:nth-child(2) {
    margin-left: 20px;
    margin-top: 0px; }
  .button:hover {
    background-color: white; }
    .button:hover .button__top:before,
    .button:hover .button__right:before,
    .button:hover .button__bottom:before,
    .button:hover .button__left:before {
      transition-delay: 0.2s; }
    .button:hover .button__top:before {
      transform: translateX(-100%); }
    .button:hover .button__right:before {
      transform: translateY(-100%); }
    .button:hover .button__bottom:before {
      transform: translateX(100%); }
    .button:hover .button__left:before {
      transform: translateY(100%); }
    .button:hover .button__top,
    .button:hover .button__right,
    .button:hover .button__bottom,
    .button:hover .button__left {
      transition-delay: 0s; }
    .button:hover .button__top {
      transform: translateX(100%); }
    .button:hover .button__right {
      transform: translateY(100%); }
    .button:hover .button__bottom {
      transform: translateX(-100%); }
    .button:hover .button__left {
      transform: translateY(-100%); }
    .button:hover .button__link {
      color: #00BCE8; } }

