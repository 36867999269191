body.no-scroll,
.wrapper.should-animate-in-out {
  overflow: hidden;

  .wrapper__bg {
    opacity: 0;
    transform: scale(0.8);
  }
}

.transition__logo {
  height: 100px;
  left: 50%;
  opacity: 1;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity $long $cubic-bezier,
    width $long $cubic-bezier,
    height $long $cubic-bezier;
  width: 100px;
  z-index: 501;

  .st0 {
    fill: $white;
  }

  &.should-animate-out {
    height: 150px;
    opacity: 0;
    width: 150px;
  }
}

.page {
  transition: opacity $xlong $cubic-bezier $short;
}

.wrapper {
  overflow-x: hidden;

  &:before {
    background-color: rgba($white, 0);
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: background $long ease-in-out;
    width: 100vw;
    z-index: 0;
  }
}

.wrapper.has-background:before {
  background-color: rgba($white, 0.8);
  transition-duration: $xlong;
}

.wrapper__bg {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: scale(1);
  transition: all 600ms $cubic-bezier;
  width: 100%;
  z-index: -1;
}

.wrapper__bgText {
  color: transparent;
  font-family: $hangul;
  font-size: 150vh;
  transform: translateY(5%);
  white-space: nowrap;
  -webkit-text-stroke: 2px $white;
}

.Transition-enter {
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition-delay: 0ms;
  transition-duration: 0ms;
  width: 100%;
  z-index: 1;
}

.Transition-enter-active {
  opacity: 0;
}

.Transition-enter-done {
  opacity: 1;
}

.Transition-exit {
  opacity: 1;
  overflow: hidden;
  position: relative;
  transition-delay: 0ms;
  z-index: 10;
}

.Transition-exit-active {
  opacity: 0;
}
