.video {
  background-color: $navy;
  font-size: 0;
  overflow: hidden;
  border-radius: 10px;

  > video {
    opacity: 0;
    transition: opacity $mid $cubic-bezier;
    width: 100%;
  }

  > video[poster] {
    object-fit: cover;
  }

  &.is-visible {
    > video {
      opacity: 1;
    }
  }
}
