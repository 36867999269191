.home {
  height: 100vh;
  position: relative;
}

.home__header {
  align-items: center;
  display: flex;
  height: 100vh;
  left: 0;
  overflow: hidden;
  padding: 0 40px;
  position: absolute;
  top: 0;
  width: 100%;
}

.home__headerText {
  margin: 0 auto;
  max-width: $max-width;
  width: 100%;
}

.home__large > div {
  font-size: 110px;
  font-weight: 700;
  line-height: 120px;
  margin-left: -5px;
}

.home__h1 {
  font-family: $display;
  font-size: 140px;
  font-weight: 400;
  line-height: 130px;
}

.home__sub {
  font-size: 23px;
  line-height: 29px;
  margin-top: 30px;
  position: relative;
}

.home__subsub {
  margin: 10px 0 25px;
}

@media screen and (max-width: $mdViewport) {
  .home__header {
    padding: 0 30px;
  }

  .home__large > div {
    font-size: 90px;
    line-height: 90px;
  }

  .home__h1 {
    font-size: 100px;
    line-height: 110px;
  }

  .home__sub {
    font-size: 19px;
    line-height: 23px;
    margin-top: 10px;
  }
}

@media screen and (max-width: $smViewport) {
  .home__header {
    padding: 0 20px;
  }

  .home__large > div {
    font-size: 65px;
    line-height: 72px;
    margin-left: -3px;
  }

  .home__h1 {
    font-size: 80px;
    line-height: 90px;
  }

  .home__sub {
    margin-top: 15px;
  }
}
