.about {
  margin: 0 auto;
  max-width: $max-width;
  padding: 120px 0 150px;
}

.about__hover,
.about__link {
  color: $dark-gray;
  position: relative;
  text-decoration: none;

  &:after {
    background-color: $blue;
    bottom: 4px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transition: width $short $cubic-bezier;
    width: 100%;
  }
}

.about__link {
  cursor: pointer;
  font-weight: 600;
}

.about__intro,
.about__what,
.about__where {
  width: 60%;
}

.about__intro {
  position: relative;
  z-index: 1;
}

.about__who {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.about__where {
  margin: 0 auto;
}

.about__images {
  height: 300px;
  position: relative;
  width: 40%;
}

.about__image {
  box-shadow: 0 0 10px rgba($dark-gray, 0.3);
  left: 50%;
  margin: 0 20px;
  max-width: 450px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: all $short $cubic-bezier;
  transform: scale(1.075) translate(-50%, -50%);
  width: 100%;

  &.is-visible {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

.about__what {
  margin: 75px 0 0 auto;
}

.about__where {
  margin-top: 75px;
}

.about__h1 {
  font-size: 120px;
  line-height: 120px;
}

.about__p {
  font-size: 28px;
  line-height: 36px;
  margin-top: 10px;
}

@media screen and (max-width: $xlViewport) {
  .about__who,
  .about__what {
    padding: 0 50px;
  }
}

@media screen and (max-width: $lgViewport) {
  .about__what,
  .about__where {
    width: 70%;
  }
}

@media screen and (max-width: $mdViewport) {
  .about {
    padding: 40px 0 80px;
  }

  .about__h1 {
    font-size: 72px;
    line-height: 72px;
  }

  .about__hover:after,
  .about__link:after {
    bottom: 2px;
  }

  .about__images {
    height: 280px;
    overflow: hidden;
    width: 100%;
  }

  .about__image {
    height: calc(100% - 20px);
    margin: 0;
    overflow: hidden;
    top: 10px;
    transform: scale(1.075) translate(-50%, 0);
    width: calc(100% - 20px);

    &.is-visible {
      transform: scale(1) translate(-50%, 0);
    }
  }

  .about__who {
    flex-flow: column-reverse;
  }

  .about__who,
  .about__what,
  .about__where {
    padding: 0 45px 0 20px;
  }

  .about__intro {
    margin-top: -70px;
  }

  .about__intro,
  .about__what,
  .about__where {
    width: 100%;
  }

  .about__what,
  .about__where {
    margin: 40px auto 0;
  }

  .about__p {
    font-size: 19px;
    line-height: 26px;
    margin-top: 10px;
  }
}

@media screen and (max-width: $mdViewport) and (max-height: 400px) {
  .about__images {
    height: 220px;
  }
}

@media screen and (max-width: $smViewport) {
  .about {
    padding-top: 5px;
  }

  .about__intro {
    margin-top: -80px;
  }

  .about__images {
    height: 240px;
    transform: translateX(-10px);
    min-width: 320px;
  }
}

@media screen and (min-width: $mdViewport) {
  .about__hover:hover:after,
  .about__link:hover:after {
    left: auto;
    right: 0;
    width: 0;
  }
}
