$hangul: 'Black Han Sans';
$sans: 'Work Sans', 'Helvetica', 'sans-serif';
$display: 'Staatliches', 'serif';

$black: #000000;
$white: #FFFFFF;
$dark-gray: #222222;
$darkish-gray: #3A3A3A;
$off-white: #EFEFEF;
$gray: #D1D1D1;
$navy: #212D3A;
$blue: #00BCE8;
$red: #E54C4C;
$orange: #F9AD26;

$cubic-bezier: cubic-bezier(0.645, 0.045, 0.355, 1);
$xlong: 500ms;
$long: 400ms;
$mid: 300ms;
$short: 200ms;

$max-width: 1200px;

$smViewport: 479px;
$mdViewport: 767px;
$lgViewport: 1079px;
$xlViewport: 1279px;
$xxlViewport: 1599px;
