.caseStudy {
  margin: 0 auto;
  max-width: $max-width;
  padding-bottom: 100vh;
  pointer-events: none;
  position: relative;
  z-index: 10;
}

.caseStudy__header {
  min-height: 100vh;

  @media screen and (max-width: $smViewport) {
    min-height: auto;
  }
}

.caseStudy__header,
.caseStudy__images {
  pointer-events: auto;
}

.caseStudy__h1 {
  font-size: 90px;
  line-height: 100px;
  margin-top: 40vh;
}

.caseStudy__p,
.caseStudy__note {
  margin-top: 20px;
  width: 75%;
}

.caseStudy__note {
  font-size: 16px;
  line-height: 1.2;
}

.caseStudy__cta {
  color: $blue;
  font-size: 23px;
  margin-top: 25px;
}

.caseStudy__link {
  color: $blue;
  position: relative;
  text-decoration: none;

  &:after {
    background-color: $blue;
    bottom: 1px;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transition: width $short $cubic-bezier;
    width: 100%;
  }
}

.caseStudy__aside {
  margin: 60px auto 0;
  width: 60%;
}

.caseStudy__h2 {
  color: $red;
  font-family: $display;
  font-size: 60px;
  margin-bottom: 20px;
}

.caseStudy__emphasis {
  font-weight: 600;
}

.caseStudy__images {
  align-items: center;
  display: flex;
  flex-flow: column;
  margin: 120px auto 0;
  max-width: $max-width;
}

.caseStudy__vimeo {
  background-color: $navy;
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

.caseStudy__vimeoVideo {
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity $mid $cubic-bezier;
  top: 0;
}

.caseStudy__vimeo.is-visible {
  .caseStudy__vimeoVideo {
    opacity: 1;
  }
}

.caseStudy__video,
.caseStudy__image {
  width: 100%;
}

.caseStudy__image,
.caseStudy__video,
.caseStudy__vimeo {
  box-shadow: 0 0 10px rgba($dark-gray, 0.3);
  margin-bottom: 50px;
}

.caseStudy__next {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all $short $cubic-bezier;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}

.caseStudy__nextCta {
  color: $dark-gray;
  font-family: $display;
  text-align: center;
  text-decoration: none;
}

.caseStudy__nextLabel {
  display: block;
  font-size: 72px;

  &:after {
    background-color: $dark-gray;
    content: '';
    display: block;
    height: 4px;
    margin: 15px auto;
    width: 40px;
  }
}

.caseStudy__caseStudy {
  color: $blue;
  font-size: 120px;
}

body.is-showing-next {
  .caseStudy__next {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: $xlViewport) {
  .caseStudy {
    padding: 0 50px 100vh;
  }
}

@media screen and (max-width: $mdViewport) {
  .caseStudy {
    padding: 0 42.5px 100vh 20px;
  }

  .caseStudy__h1 {
    font-size: 60px;
    line-height: 68px;
    margin-top: 120px;
  }

  .caseStudy__h2 {
    font-size: 48px;
  }

  .caseStudy__p,
  .caseStudy__note,
  .caseStudy__aside {
    width: 100%;
  }

  .caseStudy__images {
    margin-top: 60px;
  }

  .caseStudy__nextLabel {
    font-size: 60px;
  }

  .caseStudy__caseStudy {
    font-size: 80px;
  }
}

@media screen and (max-width: $smViewport) {
  .caseStudy__video,
  .caseStudy__image,
  .caseStudy__vimeo {
    margin-bottom: 25px;
  }

  .caseStudy__caseStudy {
    font-size: 72px;
  }

  .caseStudy__scroll {
    display: none;
  }
}

@media screen and (min-width: $mdViewport) {
  .caseStudy__link:hover:after {
    left: auto;
    right: 0;
    width: 0;
  }
}
